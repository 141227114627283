import { GridColTypeDef } from '@mui/x-data-grid-pro';
import {
  GetSingleSelectCustomField,
  OperationStatus,
} from '../../home/v1/typings';
import { Country } from '../../../shared/typings';

export interface ColumnData
  extends CustomerSegmentField,
    GridColTypeDef<CustomerSegmentFilterContact> {
  field: string;
}

export interface CustomerSegmentField {
  id: string;
  name: string;
  isInternal: boolean;
  dataType: CustomerSegmentFilterType;
  list?: GetSingleSelectCustomField[];
}

export interface CustomerSegmentFieldOperator {
  id: string;
  name: string;
  valueType: CustomerSegmentFieldOperatorValueType;
  type: CustomerSegmentFilterOperatorType;
  suffix?: CustomerSegmentFieldOperatorSuffixType[];
}

export enum CustomerSegmentType {
  STATIC_SEGMENT = 'STATIC_SEGMENT',
  DYNAMIC_SEGMENT = 'DYNAMIC_SEGMENT',
}

export enum CustomerSegmentColumnSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum ExportCustomerSegmentResponseChannel {
  WHATSAPP = 'WHATSAPP',
  DIRECT = 'DIRECT',
}

export interface ExportCustomerSegmentResponse {
  fileLink: string;
  fileName: string;
  fileSize: number;
  exportChannel: ExportCustomerSegmentResponseChannel;
  whatsappReceiver: string;
}

export interface CustomerSegment {
  id: string;
  name: string;
  description?: string;
  type: CustomerSegmentType;
  filters?: CustomerSegmentFilter | null;
  userPreference?: {
    fieldIds?: string[];
    sort?: {
      fieldId: string;
      direction: CustomerSegmentColumnSortDirection;
    }[];
  };
}

export interface CustomerSegmentFilterContactCustomField {
  id: string;
  name: string;
  value: string;
}

export interface CustomerSegmentFilterContact {
  id: string;
  customFieldValues: Record<string, string | number | boolean | string[]>;
}

export interface CustomerSegmentFilter {
  type: CustomerSegmentFilterConjunction;
  conditions: CustomerSegmentFilterCondition[];
}

export enum CustomerSegmentFilterValueKey {
  SUFFIX = 'suffix',
  VALUE = 'value',
  START = 'start',
  END = 'end',
}

export interface CustomerSegmentFieldOperatorValue {
  type: CustomerSegmentFieldOperatorValueType;
  value?: string | number | boolean | string[];
  start?: string | number | boolean;
  end?: string | number | boolean;
  suffix?: CustomerSegmentFieldOperatorSuffixType;
}

export interface CustomerSegmentFilterCondition {
  id: string;
  name?: string;
  type: CustomerSegmentFilterType;
  operator: CustomerSegmentFilterOperatorType;
  value?: CustomerSegmentFieldOperatorValue;
}

export interface CustomerSegmentFilterOperator {
  type: CustomerSegmentFilterType;
  values: CustomerSegmentFilterOperatorValue[];
}

export interface CustomerSegmentFilterOperatorValue {
  id: CustomerSegmentFilterOperatorType;
  name: string;
}

export enum CustomerSegmentFilterType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  SINGLE_SELECT = 'single_select',
  ARRAY = 'array',
}

export enum CustomerSegmentFieldOperatorSuffixType {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export enum CustomerSegmentFieldOperatorValueType {
  VALUE = 'VALUE',
  BETWEEN = 'BETWEEN',
  NO_VALUE = 'NO_VALUE',
  DATE_PERIOD = 'DATE_PERIOD',
}

export enum CustomerSegmentFilterOperatorType {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  IS_SET = 'IS_SET',
  IS_NOT_SET = 'IS_NOT_SET',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL_TO = 'LESS_THAN_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL_TO = 'GREATER_THAN_EQUAL_TO',
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
  ON_DATE = 'ON_DATE',
  NOT_ON_DATE = 'NOT_ON_DATE',
  BEFORE_DATE = 'BEFORE_DATE',
  AFTER_DATE = 'AFTER_DATE',
  IN_THE_LAST = 'IN_THE_LAST',
  NOT_IN_THE_LAST = 'NOT_IN_THE_LAST',
  IN_THE_NEXT = 'IN_THE_NEXT',
  NOT_IN_THE_NEXT = 'NOT_IN_THE_NEXT',
  SINCE_DATE = 'SINCE_DATE',
  UNTIL_DATE = 'UNTIL_DATE',
}

export enum CustomerSegmentFilterConjunction {
  OR = 'OR',
  NOT_OR = 'NOT_OR',
  AND = 'AND',
  NOT_AND = 'NOT_AND',
}

export interface GetCustomerSegmentsPaginationData {
  search?: string;
  total?: number;
  sortOptions?: {
    sort?: {
      fieldId: string;
      direction: CustomerSegmentColumnSortDirection;
    }[];
    nextSearchAfter?: string[];
  };
  filters?: CustomerSegmentFilter | null;
  timezone?: string;
  hasMore: boolean;
  isApproxTotal?: boolean;
}

export type CustomerSegmentMetadataDataTypeOperator = Record<
  CustomerSegmentFilterType,
  CustomerSegmentFieldOperator[]
>;

export interface GetCustomerSegmentsMetaData {
  fields: CustomerSegmentField[];
  dataTypeOperator: CustomerSegmentMetadataDataTypeOperator;
}

export interface GetCustomerSegments {
  segments: CustomerSegment[];
  nextSearchAfter?: string[];
  hasMore: boolean;
}

export interface GetCustomerSegment extends CustomerSegment {
  timezone: string;
}

export interface GetCustomerSegmentContacts {
  customers: CustomerSegmentFilterContact[];
  hasMore: boolean;
  total: number;
  nextSearchAfter?: string[];
  isApproxTotal?: boolean;
}

export interface GetCustomerSegmentContactsCount {
  count: number;
}

export interface GetCustomerSegmentAutocompleteValues {
  fieldId: string;
  loading?: boolean;
  values: string[];
  nextSearchAfter?: string[];
  hasMore: boolean;
}

export interface ImportExcelFileStatus {
  operationId: string;
  status: OperationStatus;
  fileName: string;
  failedCsvLink: string | null;
  successfulRecords: number;
  totalRecords: number;
}

export interface ImportExcelFileStatusResponse {
  operations: Array<ImportExcelFileStatus>;
}

export interface SheetsDataPayload {
  [sheetName: string]: { columnNames: string[] };
}

export interface FieldData {
  id?: string;
  description?: string;
  fieldType: string;
  name: string;
  required?: boolean;
  type: string;
  unique?: boolean;
  uniqueName: string;
  valueDescription?: string;
}

export interface MappedColumnData {
  name: string;
  value: string;
  type?: string | Country;
}

export interface FieldDataMap
  extends Map<FieldData, MappedColumnData | undefined> {}

export interface SheetFieldsData extends Map<string, FieldDataMap> {}

export interface SheetColumnNamesData
  extends Map<string, { name: string; value: string }[] | undefined> {}

interface TypeConstraints {
  date: {
    format: string[];
  };
  tags: {
    format: string[];
  };
}

interface FieldMappingRequest {
  columnName: string;
  format?: string | Country;
}

interface FieldMappingsRequest {
  staticFields: Record<string, FieldMappingRequest>;
  customFields: Record<string, FieldMappingRequest>;
}

export interface SheetMappingsRequest {
  [sheetName: string]: FieldMappingsRequest;
}

interface Field {
  fieldName: string;
}

interface StaticFields {
  [columnName: string]: Field;
}

interface CustomFields {
  [columnName: string]: Field;
}

type ProbableMapping = {
  staticFields: StaticFields;
  customFields: CustomFields;
};
export interface ProbableMappings {
  [sheetName: string]: ProbableMapping;
}

export interface FieldsData {
  staticFields: Array<{
    uniqueName: string;
    name: string;
    type: string;
    required: boolean;
  }>;
  customFields: Array<{
    uniqueName: string;
    name: string;
    type: string;
  }>;
  typeConstraints: TypeConstraints;
  probableMappings: ProbableMappings;
}

export interface AssignStaticFieldsValues {
  [key: string]: {
    columnName: string;
    countryCode?: string;
    tagSeparator?: string;
  };
}

export interface AssignCustomFieldsValues {
  [key: string]: {
    columnName: string;
    format?: string;
  };
}

export enum SELECTOR {
  COLUMN = 'COLUMN',
  DATE = 'DATE',
  TAG = 'TAG',
}

export interface GroupedFieldsData {
  [key: string]: {
    name: string;
    uniqueName: string;
    type: string;
    fieldType: string;
    required?: boolean;
  }[];
}

export enum DeleteCustomerActionType {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
  SINGLE = 'SINGLE',
  NONE = 'NONE',
}

export enum DeleteConfirmationType {
  EMAIL_INPUT = 'EMAIL_INPUT',
  DELETE_CONFIRMATION_NOTIFY = 'DELETE_CONFIRMATION_NOTIFY',
  DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  NONE = 'NONE',
}

export interface DeleteCustomersType {
  segmentId: string | null;
  isAllSelected: boolean;
  segmentType: CustomerSegmentType;
  excludedCustomerIds: string[];
  includedCustomerIds: string[];
  code?: string;
}

export enum ChannelsSortingTypes {
  DATE_CREATED = 'DATE_CREATED',
  NAME = 'NAME',
  LAST_MESSAGE_GROUP_DATE = 'LAST_MESSAGE_GROUP_DATE',
}

export enum ChannelsSortingOrderBy {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface ChannelsPaginationParams {
  hasMore: boolean;
  afterGroupId: string | null;
  afterGroupName: string | null;
  afterDateCreated: string | null;
  afterLastMessageAt: string | null;
}

export interface ChannelsSortingOption {
  name: string;
  type: ChannelsSortingTypes;
  format: ChannelsSortingOrderBy;
}

export enum IMPORT_BROADCAST_TYPE {
  EXISTING = 'EXISTING',
  NEW = 'NEW',
  COLUMN_MAPPING = 'COLUMN_MAPPING',
}
