import { atom } from 'jotai';

import {
  createRequestAtom,
  createPaginatedRequestAtom,
} from '../../../shared/utils/request-atom';
import {
  ChannelsPaginationParams,
  CustomerSegment,
  CustomerSegmentFilterContact,
  GetCustomerSegment,
  GetCustomerSegmentsMetaData,
  GetCustomerSegmentsPaginationData,
  IMPORT_BROADCAST_TYPE,
  ImportExcelFileStatus,
} from '../typings';
import { ChannelWithId } from '../../../shared/typings/channel-management';

export const customerSegmentsMetaDataAtom =
  createRequestAtom<GetCustomerSegmentsMetaData>();

export const getAllCustomerSegmentsAtom = createPaginatedRequestAtom<
  CustomerSegment,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const getCustomerSegmentAtom = createRequestAtom<
  GetCustomerSegment | undefined
>();

export const activeCustomerSegmentAtom = atom<CustomerSegment | null>(null);

export const getAllCustomerSegmentContactsAtom = createPaginatedRequestAtom<
  CustomerSegmentFilterContact,
  GetCustomerSegmentsPaginationData
>({ loading: false });

export const importExcelFilesStatusAtom = atom<ImportExcelFileStatus[] | null>(
  null
);

export const importCustomersNotificationAtom = atom<boolean>(false);

export const isExcelFileUploadLoadingAtom = atom<boolean>(false);

export const importCustomersModalVisibleAtom = atom<boolean>(false);

export const excelUploadStatusModalVisibleAtom = atom<boolean>(false);

export const channelsPaginationAtom = createPaginatedRequestAtom<
  ChannelWithId,
  ChannelsPaginationParams
>({ loading: false });

export const selectedBroadcastDetailsAtom = atom<{
  id: string | null;
  name: string | null;
} | null>(null);

export const selectedBroadcastTypeAtom = atom<IMPORT_BROADCAST_TYPE>(
  IMPORT_BROADCAST_TYPE.COLUMN_MAPPING
);
