type RequestConfig = {
  baseUrl: string;
  publicUrl: string;
  dtCallbackUrl: string;
  searchUrl: string;
  socketUrl: string;
  qsS3BucketName: string;
  qsS3BucketRegion: string;
  qsS3Domain: string;
  qsCloudFrontDomain: string;
  redirectUrl: string;
  qsApiUrl: string;
  smsServiceUrl: string;
};

let requestConfig: RequestConfig;

if (process.env.REACT_APP_ENV === 'production') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    redirectUrl: 'https://web.doubletick.io',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'production-local') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    redirectUrl: 'https://web.doubletick.io',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'production-2') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging') {
  requestConfig = {
    baseUrl: 'https://api-staging.doubletick.io',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket-staging.doubletick.io',
    qsApiUrl: 'https://api-staging.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging2') {
  requestConfig = {
    baseUrl: 'https://api-staging-2.doubletick.io',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api-staging-2.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging3') {
  requestConfig = {
    baseUrl: 'https://api-staging-3.doubletick.io',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://doubletick.io',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api-staging-3.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'stagingWithProd') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://dt-staging.dev.quicksell.co',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else if (process.env.REACT_APP_ENV === 'staging2WithProd') {
  requestConfig = {
    baseUrl: 'https://api.doubletick.io',
    redirectUrl: 'https://desktop-2.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://dt-staging.dev.quicksell.co',
    publicUrl: 'https://merchant.api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://socket.doubletick.io',
    qsApiUrl: 'https://api.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://getsmsotp.com',
  };
} else {
  requestConfig = {
    baseUrl: 'https://crm-2.dev.quicksell.co',
    redirectUrl: 'https://desktop.crm.dev.quicksell.co',
    dtCallbackUrl: 'https://dt-staging.dev.quicksell.co',
    publicUrl: 'https://merchant.api.quicksell.co',
    qsApiUrl: 'https://api.quicksell.co',
    searchUrl: 'https://search.quicksell.co',
    socketUrl: 'https://crm-socket.dev.quicksell.co',
    qsS3BucketName: 'quicksales',
    qsS3BucketRegion: 'ap-south-1',
    qsS3Domain: 'https://s3.amazonaws.com/quicksales',
    qsCloudFrontDomain: 'https://cdn.quicksell.co',
    smsServiceUrl: 'https://sms-service.dev.quicksell.co',
  };
}

export default requestConfig;
